import React from 'react';
import styled from 'styled-components';
import InlineYearPicker from './InlineYearPicker';
import InlineInfoButton from '../Resultsdisplay/IconWithExplanation/IconWithExplanation';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    flex-wrap: wrap;
    @media (max-width: 700px) {
        justify-content: center;
    }
`;
const Heading = styled.h2`
    margin-block: 0px;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    line-height: 1.6;
    color: #1C2654;
    text-align: left;
    font-size: 28px;
    display: inline; 
    @media (min-width: 700px) {
    }
    @media (max-width: 700px) {
        font-size: 5.5vW;
    }
    @media (max-width: 400px) {
        font-size: 36px;
    }
`;



interface SavingsAmountLableWithSelectorProps {
    duration: number;
    setDuration: (value: number) => void;
  }
const SavingsAmountLableWithSelector = ({   duration, setDuration }: SavingsAmountLableWithSelectorProps) => {

    return (
        <Container>
            <Heading>De neste</Heading>
            <InlineYearPicker duration={duration} setDuration={setDuration} />
            <span style={{ display: 'flex', }}>
            <Heading>kan du spare</Heading>
            <InlineInfoButton infoText="Vi kalkulerer dette ved å se på hvor mye du sparer på å bytte til den beste renten du kan oppnå i markedet. Vi tar utgangspunktet i et anuitetslån med 30års løpetid." />
            </span>
        </Container>
    );
};

export default SavingsAmountLableWithSelector;
