export interface Bank {
    _id: {
      $oid: string;
    };
    Country: string;
    f_orgnr: number;
    bankId: string;
    title: string;
    f_url: string;
    sort_order: number;
    f_bb_adresse1: string;
    Address2: {
      $numberDouble: string; // or 'NaN' if it's always NaN for Address2
    };
    f_bb_epost: string;
    f_bb_postnr: number;
    f_bb_poststed: string;
    f_bb_telefon: string;
    f_marked_boliglan: string;
    f_marked_boliglan_info: string;
    f_tilbyr_boliglan: boolean;
    f_tilbyr_forbrukslan: boolean;
    f_tilbyr_dagligbank: boolean;
    f_tilbyr_banksparing: boolean;
    f_tilbyr_kredittkort: boolean;
    CurrentRate: number;
  }

  export interface FinansPortalen {
    link: string;
    MortgageDealID: string;
    Timestamp_update: Date;
    ProductName: string;
    LenderID_Source: string;
    LenderBrandName: string;
    ProductPackage_Ref: string;
    ProductPackage_Name: string;
    Restr_ConstructionLoan: boolean;
    Restr_FirstHomeLoan: boolean;
    Restr_YouthLoan: boolean;
    InterestCapitalisationMonths: number;
    Restr_HolidayHome: boolean;
    Restr_MaxAge: number;
    MaxInterestOnlyYears: number;
    Restr_MaxLTV: number;
    Restr_MaxDurationYears: number;
    Restr_InterimFinance: boolean;
    Restr_MinAge: number;
    IsOpenCredit: boolean;
    InterestCapitalisationMethod: string;
    InterestFixedYears: number;
    IsRateCeling: boolean;
    NoProductBundleRequired: boolean;
    Restr_MinLendingAmount: number;
    Fee_Monthly: number;
    Int_Nominal: number;
    Restr_GreenLoan: boolean;
    Fee_Establishment_type: string;
    Fee_Establishment_Amount: number;
    Restr_MaxLendingAmount: number;
    Source: string;
    Imported: Date;
    Conditions: string;
    Restr_Membership?: string;
    LendingArea?: string;
  }
  
const baseUrl = 'https://api.clinsjweb.com';
// const baseUrl = 'https://localhost:3333';
class APIService {
    static async fetchBanks(): Promise<Bank[]> {
      const response = await fetch(`${baseUrl}/banks`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }
    static async fetchDeals(): Promise<FinansPortalen[]> {
        const response = await fetch(`${baseUrl}/deals`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      }
  }
  
  export default APIService;