import React from 'react';
import styled from 'styled-components';
import SubResultDisplayLabel from './ResultsComponents/SubResultDisplayLabel';

const Container = styled.div`
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 700px) {
        flex-direction: column; // Stacks the children vertically
        align-items: center; // Centers the children horizontally
        justify-content: center; // Helps with vertical centering if needed
        margin-top: 40px;
    }
`;

const ResultNumberDisplay = styled.h4`
    margin: 0;
    font-family: Poppins, sans-serif;
    color: rgb(255, 141, 105);
    font-size: 24px;
    font-weight: 600;
    @media (max-width: 700px) {
        font-size: 5.5vW;
        margin-block: 20px
    }
    @media (max-width: 400px) {
        font-size: 30px;
    }
`;

const SuffixStyle = styled.span`
    color: #1C2654;
`;
    

interface SubResultDisplayProps {
    label: string;
    infoText: string;
    value: string;
    suffix: string;
}

const SubResultDisplay = ({ label, infoText, value, suffix }: SubResultDisplayProps) => {
    return (
        <Container>
            <SubResultDisplayLabel label={label} infoText={infoText} />
            <ResultNumberDisplay>{value} <SuffixStyle>{suffix}</SuffixStyle></ResultNumberDisplay>
        </Container>
    );
};

export default SubResultDisplay;
