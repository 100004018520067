import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from "@mui/icons-material/Info";
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Styled components
const InfoButtonWrapper = styled.span`
    align-items: center;
    display: inline-flex;
    flex-wrap: nowrap;
    position: relative;  // Add this line
`;

interface StyledTypographyProps {
    xPos?: string;  // Optional prop for demonstration
    xPosPhone?:string;
  }

  const InfoPopup = styled(Typography)<StyledTypographyProps>`
  padding: 10px;
  color: #1C2654;
  top: 100%;          
  position: absolute;
  z-index: 10;
  background-color: #F3F4F6;
  width: 75vw;
  left: -30vw;
  max-width: 400px;
  border: 1px solid #1C2654;
  text-align: left;
  @media (max-width: 700px) {
  left: -75vw;
  }
`;
interface InlineInfoButtonProps {
    infoText: string;
    xPositon?: string;
    xPosPhone?: string;
}

const InlineInfoButton = ({ infoText, xPositon, xPosPhone }: InlineInfoButtonProps) => {
    const [showSaveInfo, setShowSaveInfo] = useState(false);

    return (
            <InfoButtonWrapper>
                <IconButton
                    onClick={() => setShowSaveInfo(!showSaveInfo)}
                    size="small"
                >
                    <InfoOutlinedIcon sx={{ alignSelf: "start" }} />
                </IconButton>
                {showSaveInfo && (
                <InfoPopup variant="body1" xPos={xPositon} xPosPhone={xPosPhone}>
                    {infoText}
                </InfoPopup>
            )}
            </InfoButtonWrapper>
    );
};

export default InlineInfoButton;
