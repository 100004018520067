import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  Box,
  Grid,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import APIService, { Bank, FinansPortalen } from "../api/BankService";
import { useQuery } from "react-query";
import { extractDealAndCreateDiplayName } from "../utils/dealToGraphDataMapper";
import MortgageGraph, { MortgageDataItem } from "./RateGraph/MortgageGraph";
import { useDebounce } from "../utils/debounce";
import goodRent from "../assets/images/Firstplace.png";
import missingFieldAlertImg from "../assets/images/coinInterest.svg";
import highLoanAlertImg from "../assets/images/Low_Prospect.svg";
import locked from "../assets/images/clinsjPadlock.svg";
import { getSavingsAmount } from "../utils/savingsCalculator";
import NokAmountInput from "./FormControls/NokAmountInput";
import InterestInput from "./FormControls/InterestInput";
import Divider from '@mui/material/Divider';
import SavingsDisplay from "./Resultsdisplay/SavingsDisplay";
import SubResultDisplay from "./Resultsdisplay/SubResultDisplay";


const useQueryConfig = {
  staleTime: Infinity, // Data never becomes stale
  cacheTime: Infinity, // Data stays in cache forever
  refetchOnWindowFocus: false, // Do not refetch on window focus
  refetchOnReconnect: false, // Do not refetch on reconnection
};

interface ResultData {
  minInterestRate: number;
  maxInterestRate: number;
  minSavings: number;
  betterOffers: number;
}

const Calculator: React.FC = () => {
  const [duration, setDuration] = useState(1);

  const [loanAmount, setLoanAmount] = useState(4000000);
  const [propertyValue, setPropertyValue] = useState(5500000);
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [interestRate, setInterestRate] = useState(6.4);
  const [isUnder34, setIsUnder34] = useState(true);
  const [allert, setAllert] = useState("");
  const [filteredDeals, setFilteredDeals] = useState<FinansPortalen[] | null>(
    null
  );
  const [displayData, setDisplayData] = useState<MortgageDataItem[] | null>(
    null
  );
  const [banks, setBanks] = useState<Bank[] | null>(null);

  const debouncedFilteredDeals = useDebounce(filteredDeals, 1000);
  const debouncedInterestRate = useDebounce(interestRate, 1000);

  const [resultData, setResultData] = useState<ResultData>({
    minInterestRate: 0,
    maxInterestRate: 0,
    minSavings: 0,
    betterOffers: 0,
  });

  const { data: rawBanks, isLoading: banksLoading } = useQuery<Bank[]>(
    "banks",
    APIService.fetchBanks,
    useQueryConfig
  );
  const { data: deals } = useQuery<FinansPortalen[]>(
    "deals",
    APIService.fetchDeals,
    useQueryConfig
  );


  useEffect(() => {
    if (rawBanks) {
      let filteredBanks = rawBanks.filter((bank) => bank.f_tilbyr_boliglan);
      filteredBanks = filteredBanks.sort((a, b) => a.sort_order - b.sort_order);
      setBanks(filteredBanks);
    }
  }, [rawBanks]);

  useEffect(() => {
    if (banks) {
      const selected = banks.find((bank) => bank.title === "DNB Bank ASA");
      setSelectedBank(selected!);
    }
  }, [banks]);

  useEffect(() => {
    if (selectedBank) {
      const newRate = selectedBank.CurrentRate;
      setInterestRate(newRate); // Update the interest rate state
    }
  }, [selectedBank]);

  useEffect(() => {
    if (!deals) return;

    if (!loanAmount || !propertyValue || !interestRate) {
      setAllert(
        "Det virker som du har glemt å fylle ut et felt. Vennligst fyll ut alle feltene."
      );
    } else {
      const ltv = (loanAmount * 100) / propertyValue;
      if (ltv > 85) {
        setAllert("Du har en høy belåningsgrad. ");
        return;
      }
      const correctedInteres = interestRate * 0.01;
      let filteredDeals = deals?.filter((deal) => deal.Restr_MaxLTV >= ltv);
      filteredDeals = filteredDeals?.filter(
        (deal) => deal.Int_Nominal < interestRate
      );
      filteredDeals = filteredDeals?.filter(
        (deal) => deal.InterestFixedYears === 0
      );
      filteredDeals = filteredDeals?.filter(
        (deal) => deal.Restr_MinLendingAmount <= loanAmount
      );
      filteredDeals = filteredDeals?.filter(
        (deal) => deal.Restr_MaxLendingAmount >= loanAmount
      );
      filteredDeals = filteredDeals?.filter((deal) => !deal.Restr_HolidayHome);
      filteredDeals = filteredDeals?.filter((deal) => !deal.Restr_GreenLoan);
      filteredDeals = filteredDeals?.filter((deal) => !deal.LendingArea);
      filteredDeals = filteredDeals?.filter(
        (deal) =>
          deal.Restr_Membership === undefined || deal.Restr_Membership === null
      );
      if (!isUnder34) {
        filteredDeals = filteredDeals?.filter(
          (deal) => !deal.Restr_FirstHomeLoan
        );
        filteredDeals = filteredDeals?.filter((deal) => !deal.Restr_YouthLoan);
      }

      filteredDeals = filteredDeals?.sort(
        (a, b) => a.Int_Nominal - b.Int_Nominal
      );

      setFilteredDeals(filteredDeals);
      let numberOfBetterOffers = filteredDeals?.length || 0;

      const minInterestRate =
        filteredDeals.length > 0
          ? filteredDeals[0].Int_Nominal * 0.01
          : correctedInteres;

      if (minInterestRate + 0.0001 > correctedInteres) {
        setAllert(
          "Wow! For en rente! Er du sikker på at du har fylt ut riktig?"
        );
        return;
      }

      const maxInterestRate = minInterestRate + 0.002;
      let minSavings = getSavingsAmount(
        correctedInteres,
        minInterestRate,
        loanAmount,
        duration
      );
      minSavings = Math.round(minSavings);

      console.log(
        correctedInteres,
        minInterestRate,
        maxInterestRate,
        minSavings
      );

      setResultData({
        minInterestRate,
        maxInterestRate,
        minSavings,
        betterOffers: numberOfBetterOffers,
      });
      setAllert("");
    }
  }, [loanAmount, propertyValue, interestRate, isUnder34, deals, duration]);

  useEffect(() => {
    if (!debouncedFilteredDeals) return;
    if (!debouncedInterestRate) return;

    let filteredDealData = debouncedFilteredDeals.map((deal) =>
      extractDealAndCreateDiplayName(deal)
    );
    filteredDealData.push({
      name: "Din rente",
      ownInterestRate: debouncedInterestRate,
    });
    filteredDealData = filteredDealData.sort(
      (a, b) =>
        (a.interestRate ? a.interestRate! : a.ownInterestRate!) -
        (b.interestRate ? b.interestRate! : b.ownInterestRate!)
    );
    setDisplayData(filteredDealData);
  }, [debouncedFilteredDeals, debouncedInterestRate]);

  const getImageForAlert = (alertText: string) => {
    switch (alertText) {
      case "Det virker som du har glemt å fylle ut et felt. Vennligst fyll ut alle feltene.":
        return missingFieldAlertImg;
      case "Du har en høy belåningsgrad. ":
        return highLoanAlertImg;
      case "Wow! For en rente! Er du sikker på at du har fylt ut riktig?":
        return goodRent; // Example, adjust according to your needs
      default:
        return locked; // No image for no or unknown alert
    }
  };

  if (banksLoading) return <div>Loading...</div>;

  return (
    <Box
      sx={{
        display: "block",
        "@media (min-width: 700px)": {
          display: "flex",
        },
        "@media (min-width: 800px)": {
          marginTop: "0px",
        },
      }}
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
        marginTop="0px"
        sx={{
          
          textAlign: "center",
          "& .MuiTextField-root": { margin: "5px auto", display: "flex" },
          "& .MuiSlider-root": {
            margin: "5px auto",
            width: "85%",
            maxWidth: "420px",
          },
          "& .MuiFormControl-root": {
            margin: "0px auto",
            width: "100%",
            maxWidth: "420px",
          },
          "@media (min-width: 700px)": {
            textAlign: "left",
            "& .MuiSlider-root": { margin: "5px 10px" },
            width: '30vw',
          },
        }}
      >
        <NokAmountInput
          label="Hvor mye har du i lån?"
          value={loanAmount}
          min={500000}
          max={10000000}
          step={50000}
          onValueChange={setLoanAmount}
        />
        <NokAmountInput
          label="Hvor mye er boligen din verdt?"
          value={propertyValue}
          min={500000}
          max={20000000}
          step={50000}
          onValueChange={setPropertyValue}
        />
        <FormControl component="fieldset">
          <Typography
            variant="h6"
            sx={{ fontWeight: "550", marginLeft: "0px" }}
          >
            Er du under 34 år?
          </Typography>
          <RadioGroup
            row
            aria-label="isUnder34"
            name="row-radio-buttons-group"
            value={isUnder34 ? "yes" : "no"}
            onChange={(event) => setIsUnder34(event.target.value === "yes")}
            sx={{
              marginRight: "auto",
              marginLeft: "auto",
              "@media (min-width: 700px)": {
                marginRight: "auto",
                marginLeft: "22px",
              },
            }}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Ja" />
            <FormControlLabel value="no" control={<Radio />} label="Nei" />
          </RadioGroup>
        </FormControl>
        <Typography
          variant="h6"
          sx={{ fontWeight: "550", marginLeft: "0px", marginBottom: "10px", "@media (max-width: 700px)": {marginInline: "5px"}, }}
        >
          Hvor er du kunde?
        </Typography>
        <Autocomplete
          value={selectedBank}
          onChange={(event, newValue) => {
            setSelectedBank(newValue);
          }}
          options={banks || []}
          getOptionLabel={(option) => option.title || ""}
          isOptionEqualToValue={(option, value) =>
            option.bankId === value.bankId
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Velg bank"
              variant="outlined"
              required
            />
          )}
          sx={{
            marginRight: "5px",
            marginLeft: "5px",
            "@media (min-width: 700px)": {
              marginRight: "5px",
              marginLeft: "0px",
            },
          }}
        />
        <InterestInput
          label="Hva har du i rente i dag?"
          interestRate={interestRate}
          setInterestRate={setInterestRate}
          minRate={3.1}
          maxRate={20}
          step={0.01}
        />
      </Box>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            paddingTop: 0,
            textAlign: "center",
            "@media (min-width: 700px)": {
            width: "70vw",
            },
            "@media (max-width: 700px)": {
              //  paddingLeft:"10px",
            padding: "20px 0px 0px 0px",
            },
            "@media (min-width: 800px)": {
            },
            height: "100%",
          }}
        >
          {!allert && (
            <Box
              sx={{
                "@media (min-width: 700px)": {
                  paddingLeft: "10px",
                },
              }}
            >

              <SavingsDisplay savingsAmount={resultData.minSavings} duration={duration}  setDuration={setDuration}/>
              <Divider variant="middle"/>
              <SubResultDisplay label="Bedre lån på markedet" infoText="Antall tilbud i markedet med samme krav som ditt eksisterende lån som har bedre vilkår enn ditt nåværende lån." value={resultData.betterOffers.toString()} suffix="Lån" /> 
              <SubResultDisplay label="Din rente bør ligge på" infoText="Basert på lånebeløp, sikkerhet og alder bør du ha en rente rundt dette. " value={(resultData.minInterestRate *100).toFixed(2).toString()} suffix="%" /> 

              <Box
                marginTop="20px"
                sx={{
                  width: "100-=%",
                  overflow: "hidden",
                  "@media (min-width: 800px)": { width: "98%" },
                }}
              >
                <MortgageGraph mortgageData={displayData || []} />
              </Box>
            </Box>
          )}
          {allert && (
            <Box sx={{}}>
              <Typography
                variant="h6"
                sx={{
                  paddingLeft: "10px",
                  fontWeight: "500",
                  fontSize: "20px",
                  "@media (min-width: 700px)": {
                    textAlign: "left",
                    marginLeft: "40px",
                  },
                  "@media (min-width: 800px)": {
                    fontSize: "24px",
                    fontWeight: "500",
                    marginTop: "0px",
                  },
                }}
              >
                {allert}
              </Typography>
              {getImageForAlert(allert) && (
                <img
                  src={getImageForAlert(allert)}
                  style={{
                    height: "400px",
                    width: "auto",
                    marginTop: "25px",
                    maxWidth: "75%",
                    marginInline: "auto",
                  }}
                ></img>
              )}
            </Box>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default Calculator;
