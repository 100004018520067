export const renderCustomLabel = (entry: any, index: number) => {

  const tooltipWidth = 150; // Width of the tooltip
  const tooltipHeight = 40; // Height of the tooltip
  const triangleWidth = 20;
  const triangleHeight = 10;
  const rectX = entry.x - tooltipWidth / 2; // Center the rectangle
  const rectY = entry.y - tooltipHeight - triangleHeight - 20; // Position above the point

  const trianglePath = `M${entry.x - triangleWidth / 2},${rectY + tooltipHeight}
                        L${entry.x},${rectY + tooltipHeight + triangleHeight}
                        L${entry.x + triangleWidth / 2},${rectY + tooltipHeight}
                        Z`; // Path for the triangle
  if (index === 0) { // Only for the first data point of each line
      return (
        <g>
        <rect
          x={rectX + 10}
          y={rectY}
          width={tooltipWidth}
          height={tooltipHeight}
          rx="10"
          fill={"#FF8D69"}
          stroke={"#FF8D69"}
        />
        <path d={trianglePath} fill={"#FF8D69"} stroke={"#FF8D69"} />
        <text
          x={entry.x+ 10}
          y={rectY + tooltipHeight / 2}
          fill="white"
          fontSize="14"
          fontWeight="bold"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          Du kunne vært her..
        </text>
      </g>
      );
  }
  return null;
};


export const renderCustomLabel2 = (entry: any, index: number) => {

  if (index === 0) { // Only for the first data point of each line
    return (
        <text x={entry.x -60} y={entry.y} dy={-10} fill={"#FF8D69"} fontSize={20} textAnchor="middle" fontWeight="bold">
            Du er her
        </text>
    );
}
return null;
};
