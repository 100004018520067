import React from 'react';
import styled from 'styled-components';
import SavingsAmountLableWithSelector from '../FormControls/SavingsAmountLableWithSelector';
import AmountSavingsDisplay from './ResultsComponents/AmountSavingsDisplay';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 700px) {
        flex-direction: column; // Stacks the children vertically
        align-items: center; // Centers the children horizontally
        justify-content: center; // Helps with vertical centering if needed
    }
`;

interface SavingsDisplayProps {
    savingsAmount: number,
    duration: number;
    setDuration: (value: number) => void;
}

const SavingsDisplay = ({ savingsAmount, duration, setDuration }: SavingsDisplayProps) => {

    return (
        <Container>
            <SavingsAmountLableWithSelector duration={duration} setDuration={setDuration} />
            <AmountSavingsDisplay savings={savingsAmount} />
        </Container>
    );
};

export default SavingsDisplay;
