import { useEffect, useRef, useState } from "react";

export const useDebounce = <T,>(value: T, delay: number): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    const isFirstUpdate = useRef(true);
  
    useEffect(() => {
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
        return;
      }
  
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
  
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
  
    return debouncedValue;
  };
  