import React from "react";
import styled from "styled-components";
import { FormControl, Select, MenuItem, InputBase} from "@mui/material";


// Styled components with TypeScript props
const CustomInput = styled(InputBase)`
    border-bottom: 2px solid #FF8D69;
    @media (min-width: 700px) {
      border-bottom: 3px solid #FF8D69;
      }
`;
const StyledSelect = styled(Select)`
    display: inline;
    margin-right: 5px;
    margin-left: 8px;
  && .MuiSelect-select {
    display: inline;
    padding: 8px 0px;
    font-weight: 700;
    min-height: 1rem;
    max-height: 32px;
    font-size: 28px;
    color: #1c2654;
    @media screen {
        @media (min-width: 700px) {
            font-size: 30px;
        }
        @media (max-width: 700px) {
          font-size: 5.5vW;
      }
      @media (max-width: 400px) {
        font-size: 36px;
    }
    }

  }
 

  .MuiSvgIcon-root {
    font-size: 1.5em;
    @media (min-width: 700px) {
      font-size: 1.75em;
    }
  }
  && .MuiInput-underline:before {
    border-bottom: 2px solid #FF8D69;
  }

`;


interface InlineYearPickerProps {
  duration: number;
  setDuration: (value: number) => void;
}

const InlineYearPicker: React.FC<InlineYearPickerProps> = ({
  duration,
  setDuration,
}) => {
  return (
    <FormControl variant="standard">
      <StyledSelect
        labelId="duration-select-label"
        id="duration-select"
        value={duration}
        onChange={(e) => setDuration(Number(e.target.value))}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        input={<CustomInput />}
        >
        User You also need to style the menueitems:
        <MenuItem
          value={1}
          sx={{
            fontWeight: "700",
            "@media (min-width: 700px)": { fontSize: "30px" },
          }}
        >
          12 mnd
        </MenuItem>
        <MenuItem
          value={3}
          sx={{
            fontWeight: "700",
            "@media (min-width: 700px)": { fontSize: "30px" },
          }}
        >
          3 år
        </MenuItem>
        <MenuItem
          value={7}
          sx={{
            fontWeight: "700",
            "@media (min-width: 700px)": { fontSize: "30px" },
          }}
        >
            7 år
        </MenuItem>
      </StyledSelect>
    </FormControl>
  );
};

export default InlineYearPicker;
