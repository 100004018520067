const MORTGAGE_LENGTH_YEARS = 30;
const MONTHS_IN_YEAR = 12;

export function calculateInterestAmount(annualRate: number, loanAmount: number, periodOfInterestYears: number): number {
    const r = annualRate / MONTHS_IN_YEAR;
    const n = MORTGAGE_LENGTH_YEARS * MONTHS_IN_YEAR;
    const nInterest = periodOfInterestYears * MONTHS_IN_YEAR;
    const annuity = (r * loanAmount) / (1 - Math.pow((1 + r), -n));
    const futureValue = (loanAmount * Math.pow((1 + r), nInterest)) - (annuity * ((Math.pow((1 + r), nInterest) - 1) / r));
    const principalOverPeriod = loanAmount - futureValue;
    const interestPaid = (annuity * nInterest) - principalOverPeriod;
    return interestPaid;
}

export function getSavingsAmount(annualRate: number, betterAnnualRate: number, loanAmount: number, periodOfInterestYears: number): number {
    const oldInterestPayment = calculateInterestAmount(annualRate, loanAmount, periodOfInterestYears);
    const newInterestPayment = calculateInterestAmount(betterAnnualRate, loanAmount, periodOfInterestYears);
    return oldInterestPayment - newInterestPayment;
}
