import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, InputAdornment, Stack, Slider, Box } from '@mui/material';

interface InterestInputProps {
    label: string;
    interestRate: number;
    setInterestRate: (rate: number | ((prevRate: number) => number)) => void;
    minRate: number;
    maxRate: number;
    step: number;
  }

const InterestInput: React.FC<InterestInputProps> = ({
  label,
  interestRate,
  setInterestRate,
  minRate,
  maxRate,
  step
}) => {
  const [value, setValue] = useState(interestRate.toString());

  useEffect(() => {
    setValue(interestRate.toString());
  }, [interestRate]);

  const handleInterestRateChange = (event: any, newValue?: number | number[]) => {
    let input = event.target.value;
    
    // Check if newValue is provided directly and is a number
    if (typeof newValue === 'number') {
      setInterestRate(newValue);  // Directly update the numeric state
      setValue(newValue.toString());  // Update display to reflect the new numeric input
      return; // Exit the function early as no further processing is needed
    }
  
    // For user-typed inputs, store the raw input for display update
    setValue(input);
  
    // Normalize the input for calculation by replacing ',' with '.' and removing invalid characters
    const normalizedInput = input.replace(',', '.').replace(/[^\d.]/g, '');
  
    // Attempt to parse the normalized input
    const numericValue = parseFloat(normalizedInput);
  
    // Update the state only if it's a valid number
    if (!isNaN(numericValue)) {
      setInterestRate(numericValue);
    }
  };
  
  const incrementRate = () => {
    setInterestRate((prevRate) => {
      const newRate = parseFloat((prevRate + 0.01).toFixed(2));
      if (newRate <= 20) { // Modified the maximum rate check to be 20 as an example.
        setValue(newRate.toString());
        return newRate;
      }
      return prevRate;
    });
  };

  const decrementRate = () => {
    setInterestRate((prevRate) => {
      const newRate = parseFloat((prevRate - 0.01).toFixed(2));
      if (newRate <= 20) { // Modified the maximum rate check to be 20 as an example.
        setValue(newRate.toString());
        return newRate;
      }
      return prevRate;
    });
  };


  return (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: '550', marginLeft: '0px', marginTop: '15px', marginBottom: '10px' }}>
        {label}
      </Typography>
      <Stack direction="row" spacing={0}>
        <Button variant="outlined" onClick={decrementRate} sx={{ marginLeft: '0px',  "@media (max-width: 700px)": {marginLeft: "5px"}, }}>-</Button>
        <TextField
          type="text"
          value={value}
          onChange={handleInterestRateChange}
          variant="standard"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            disableUnderline: true,
            style: { fontSize: '20px', fontWeight: '500', maxWidth: '120px', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#F3F4F6', paddingInline: '5px', borderRadius: '10px' },
          }}
          required
          sx={{
            "& .MuiInputBase-input": {
              textAlign: "center",
            },
          }}
        />
        <Button variant="outlined" onClick={incrementRate} sx={{ marginRight: '5px' }}>+</Button>
      </Stack>
      <Slider
        value={interestRate}
        onChange={handleInterestRateChange}
        min={minRate}
        max={maxRate}
        step={step}
        valueLabelDisplay="auto"
        sx={{ width: '85%' }}
        aria-labelledby="interest-rate-slider"
      />
    </Box>
  );
};

export default InterestInput;
