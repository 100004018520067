import React from 'react';
import styled from 'styled-components';
import InlineInfoButton from '../IconWithExplanation/IconWithExplanation';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    flex-wrap: wrap;
    @media (max-width: 700px) {
        justify-content: center;
    }
`;

const Label = styled.h2`
    font-family: Poppins, sans-serif;
    margin:0;
    font-weight: 550;
    line-height: 1.6;
    color: #1C2654;
    text-align: left;
    font-size: 24px;
    display: inline;  // Ensures the heading is in line with other inline elements
    @media (max-width: 700px) {
        font-size: 5vW;
        text-align: center;

    }
    @media (max-width: 400px) {
       font-size: 22px
    }
`;

interface SubResultDisplayLabelProps {
    label: string;
    infoText: string;
}

const SubResultDisplayLabel = ({ label, infoText}: SubResultDisplayLabelProps) => {

    return (
        <Container>
            <Label>{label}</Label>
            <InlineInfoButton xPosPhone="-4.5em" infoText={infoText} />
        </Container>
    );
};

export default SubResultDisplayLabel;
