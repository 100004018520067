import React, { useMemo } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
  LabelList,
} from "recharts";
import { renderCustomLabel, renderCustomLabel2 } from "./GraphLables";
// Define a type for the items in your mortgageData array.
export interface MortgageDataItem {
  interestRate?: number;
  ownInterestRate?: number;
  name: string;
}

// Define a type for your component props.
interface MortgageGraphProps {
  mortgageData: MortgageDataItem[];
  yAxisMargin?: number;
}

interface CustomTooltipProps extends TooltipProps<any, any> {
  labelFormatter?: (label: string) => string;
  formatter?: (value: number, name: string) => [string, string];
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: entry.color }}>
            {entry.name === "interestRate" || entry.name === "ownInterestRate"
              ? `Rente: ${entry.value}%`
              : `${entry.name}: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const MortgageGraph: React.FC<MortgageGraphProps> = ({
  mortgageData,
  yAxisMargin = 0.1,
}) => {
  const { lowestInterest, highestInterest } = useMemo(() => {
    const interestRates = mortgageData.flatMap((item) => {
      const rates: number[] = [];
      if (item.interestRate !== undefined) rates.push(item.interestRate);
      if (item.ownInterestRate !== undefined) rates.push(item.ownInterestRate);
      return rates;
    });
    return {
      lowestInterest: Math.min(...interestRates),
      highestInterest: Math.max(...interestRates),
    };
  }, [mortgageData]);

  const tickFormatter = (value: number): string => `${value}%`;

  return (
    <ResponsiveContainer width="100%" height={280}>
      <LineChart
        data={mortgageData}
        margin={{ top: 25, right: 5, bottom: 0, left: 0 }}
      >
         <XAxis
          dataKey="name"
          tick={false}
          padding={{ left: 10 }}
          label={{
            value: "Tilbud sortert etter pris",
            position: "insideBottomRight",
          }}
        />
        <YAxis
          domain={[
            Number((lowestInterest - yAxisMargin).toPrecision(2)),
            Number((highestInterest + yAxisMargin).toPrecision(2)),
          ]}
          tickFormatter={tickFormatter}
        />
        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
        <Line type="monotone" dataKey="interestRate" stroke="#1C2654">
          <LabelList dataKey="interestRate" content={({ x, y, index }) => renderCustomLabel({ x, y }, index!)} />

        </Line>
        <Line
          type="monotone"
          dataKey="ownInterestRate"
          stroke="#FF8D69"
          dot={{ stroke: "#FF8D69", r: 5, fill: "#FF8D69" }}
          activeDot={{ stroke: "#FF8D69", r: 5, fill: "#FF3A33" }}
        >
            <LabelList dataKey="interestRate" content={({ x, y, index }) => renderCustomLabel2({ x, y }, 0)}/>
        </Line>
        
       
        <Tooltip content={<CustomTooltip />} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MortgageGraph;
