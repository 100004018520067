import React, { useState } from "react";
import styled from 'styled-components';
import {
  TextField,
  Typography,
  Box,
  InputAdornment,
  Slider,
} from "@mui/material";

interface NokAmountInputProps {
  label: string;
  value: number;
  min: number;
  max: number;
  step: number;
  onValueChange: (value: number) => void;
}

const StyledTextField = styled(TextField)`
    margin: 0px auto;
    width: 100%;
    max-width: 420px;

  && .MuiInputBase-root {
    font-size: 20px;
    font-weight: 500;
    max-width: 150px;
    margin-left: 0px;
    margin-right: auto;
    background-color: rgb(243, 244, 246);
    padding-inline: 5px;
    border-radius: 10px;
    @media screen {
        @media (max-width: 700px) {
            margin-left: auto;
    margin-right: auto;
        }
    }
  }

  && .MuiInputBase-input {
    text-align: center;
  }

  && .MuiInputAdornment-root {
    display: flex;
    height: 0.01em;
    max-height: 2em;
    align-items: center;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.54);
    margin-left: 8px;
}
`;

const NokAmountInput: React.FC<NokAmountInputProps> = ({
  label,
  value,
  min,
  max,
  step,
  onValueChange,
}) => {
  const [displayValue, setDisplayValue] = useState(value.toLocaleString());

  const handleInputChange = (event: any, newValue?: number | number[]) => {
    const value = typeof newValue === 'number' ? newValue : parseInt(event.target.value.replace(/\D/g, ''), 10);
    if (!isNaN(value)) {
      onValueChange(value); // Assuming you still store the actual number in state for calculations
      setDisplayValue(value.toLocaleString());
    } else {
        setDisplayValue("");
    }
  };

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{ fontWeight: "550", marginBottom: "10px" }}
      >
        {label}
      </Typography>
        <StyledTextField
          type="text"
          value={displayValue}
          onChange={handleInputChange}
          variant="standard"
          InputProps={{
            endAdornment: <InputAdornment position="end">kr</InputAdornment>,
            disableUnderline: true,
          }}
          required
        />
      <Slider
        value={value}
        onChange={handleInputChange}
        min={min}
        max={max}
        step={step}
        valueLabelDisplay="auto"
        sx={{ width: "100%", margin: "20px" }}
      />
    </Box>
  );
};

export default NokAmountInput;
