import React from 'react';
import Typography from '@mui/material/Typography';


interface SavingsDisplayProps {
    savings: number;
}

const AmountSavingsDisplay = ({ savings }:SavingsDisplayProps) => {
  return (
    <Typography
    variant="h6"
    sx={{
        fontSize: "40px",
        marginBlock: '20px',
      "@media (min-width: 800px)": {
        fontSize: "50px",
      },
      "@media (max-width: 400px)": {
        fontSize: "60px",
      },
      
    }}
  >
    <span style={{ color: "#FF8D69", fontWeight: "700" }}>
      {" "}
      {`${savings.toLocaleString("no-NO")}kr`}{" "}
    </span>
  </Typography>
  );
};

export default AmountSavingsDisplay;
