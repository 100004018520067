// src/theme.tsx
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF8D69', // Primary color
      contrastText: '#FFFFFF', // Primary contrast text color
    },
    // Include other palette configurations as necessary
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#ff0000',
    },
    background: {
      default: '#f4f5fd',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
    allVariants: {
      color: '#1C2654', // Primary text color for all variants
    },
  },
  // You can further customize the theme here (e.g., breakpoints, zIndex, etc.)
});

export default theme;
